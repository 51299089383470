<template>
  <div>
    <TableFilters :title-count="sourceObject.count" :queries="queries">
      <template v-slot:filter-title>
        <span v-if="sourceObject.count">
          <span class="title-filter">Всего:</span>{{ sourceObject.count }}
        </span>
      </template>
      <template v-slot:download-button>
        <v-btn
          @click="downloadList"
          class="btn btn_color_grey btn-unload flex-right mr-4"
          :disabled="loadingBtn || sourceObject.results.length == 0"
          :loading="loadingBtn"
        >
          Выгрузить
        </v-btn>
      </template>
      <template v-slot:add-button>
        <router-link
          to="childrenGroupsCreate"
          append
          class="btn btn-add btn_color_green"
        >
          Добавить детскую группу1
        </router-link>
      </template>
    </TableFilters>
    <TableLayout
      :loading="isDateLoaded"
      to-raw="childrenGroups"
      :header="header"
      :table-data="sourceObject.results"
      :source-object="sourceObject"
    />
  </div>
</template>

<script>
import TableFilters from "@/components/tables/TableFilters";
import TableLayout from "@/components/tables/TableLayout";
import * as query from "@/helpers/query";
export default {
  name: "Groups",
  components: { TableLayout, TableFilters },
  data() {
    return {
      loadingBtn: false,
      queries: query.groupChildren,
      header: [
        {
          text: "Номер детской группы",
          value: "number"
        },
        {
          text: "Название детской группы",
          value: "name"
        },
        {
          text: "Группы пользователей",
          // value: "group"
          renderFunction: value =>
            value.group_details ? value.group_details.title : ""
        },
        {
          text: "Воспитатели",
          renderFunction: value =>
            value?.teachers_details
              ?.map(el => el.full_name + "<br/>")
              ?.join("\n")
        }
      ]
    };
  },
  computed: {
    getKindergartenId() {
      return localStorage.getItem("organization") != 1
        ? localStorage.getItem("organization")
        : localStorage.getItem("KindergartenId");
    },
    urlQuery() {
      return {
        query: this.$route.query,
        id: this.$route.params.id
      };
    },
    sourceObject() {
      return this.$store.getters.getChildrenGroups;
    }
  },
  methods: {
    downloadList() {
      this.loadingBtn = true;
      this.$store.dispatch("downloadChildrenGroupsList").then(() => {
        this.loadingBtn = false;
      });
    }
  },
  created() {
    this.$store.dispatch("clearSortQuery");
    this.$store.commit("setSearchQuery", {
      query: {
        ...this.urlQuery.query,
        teachers: this.urlQuery.id,
        kindergarten_org: this.getKindergartenId
      },
      id: this.$store.getters.getCurrentOrganization
    });
    this.isDateLoaded = true;
    this.$store.dispatch("fetchChildrenGroupByIdList").finally(() => {
      this.isDateLoaded = false;
    });
  },
  watch: {
    $route(to, from) {
      if (to.path === from.path) {
        this.$store.commit("setSearchQuery", {
          id: this.urlQuery.id,
          query: {
            ...to.query,
            children_groups: this.urlQuery.id
            // type: this.type
          }
        });
        this.isDateLoaded = true;
        this.$store.dispatch("fetchChildrenGroupByIdList").finally(() => {
          this.isDateLoaded = false;
        });
      }
    }
  }
};
</script>

<style scoped></style>
